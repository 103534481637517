import { DateTimeString, PageInfo } from 'fabscale-app';

import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';
import { RoastBatch, RoastBatchPojo } from 'fabscale-app/models/roast-batch';

export class LabSample {
  id: string;
  createdDate: DateTime;
  pictureUrl?: string;
  lotNumber?: string;
  roastBatch?: RoastBatch;
  coffeeType?: string;
  coffeeVariety?: string;
  screenSizeMode?: number;
  screenSizeHist?: CountBinValue[];
  lengthAvg?: number;
  lengthDeviation?: number;
  lengthHist?: CountBinValue[];
  widthAvg?: number;
  widthDeviation?: number;
  widthHist?: CountBinValue[];
  totalEstimatedVolume?: number;
  sampleMass?: number;
  density?: number;
  gourmetColorHist?: CountBinValue[];
  lstarAvg?: number;
  lstarDeviation?: number;
  lstarHist?: CountBinValue[];
  astarAvg?: number;
  astarDeviation?: number;
  astarHist?: CountBinValue[];
  bstarAvg?: number;
  bstarDeviation?: number;
  bstarHist?: CountBinValue[];
  colorNameMode?: string;
  colorNameHist?: CountBinValue[];
  gourmetColorAvg?: number;
  gourmetColorDeviation?: number;
  coloretteColorAvg?: number;
  coloretteColorDeviation?: number;
  numberDefects?: number;
  numberDefectsCatOne?: number;
  numberDefectsCatTwo?: number;
  defectsHist?: CountBinValue[];
  numberOfBeans?: number;
  comment?: string;

  constructor(dto: LabSampleDto) {
    this.id = dto.id;
    this.createdDate = deserializeDate(dto.createdDate);
    this.pictureUrl = dto.pictureUrl;
    this.lotNumber = dto.lotNumber;
    this.roastBatch = dto.roastBatch
      ? new RoastBatch(dto.roastBatch)
      : undefined;
    this.coffeeType = dto.coffeeType;
    this.coffeeVariety = dto.coffeeVariety;
    this.screenSizeMode = dto.screenSizeMode;
    this.screenSizeHist = dto.screenSizeHist;
    this.lengthAvg = dto.lengthAvg
      ? Number(dto.lengthAvg.toFixed(2))
      : undefined;
    this.lengthDeviation = dto.lengthDeviation
      ? Number(dto.lengthDeviation.toFixed(2))
      : undefined;
    this.lengthHist = dto.lengthHist;
    this.widthAvg = dto.widthAvg ? Number(dto.widthAvg.toFixed(2)) : undefined;
    this.widthDeviation = dto.widthDeviation
      ? Number(dto.widthDeviation.toFixed(2))
      : undefined;
    this.widthHist = dto.widthHist;
    this.totalEstimatedVolume = dto.totalEstimatedVolume;
    this.sampleMass = dto.sampleMass;
    this.density = dto.density ? Number(dto.density.toFixed(2)) : undefined;
    this.gourmetColorHist = dto.gourmetColorHist;
    this.lstarAvg = dto.lstarAvg ? Number(dto.lstarAvg.toFixed(2)) : undefined;
    this.lstarDeviation = dto.lstarDeviation
      ? Number(dto.lstarDeviation.toFixed(2))
      : undefined;
    this.lstarHist = dto.lstarHist;
    this.astarAvg = dto.astarAvg ? Number(dto.astarAvg.toFixed(2)) : undefined;
    this.astarDeviation = dto.astarDeviation
      ? Number(dto.astarDeviation.toFixed(2))
      : undefined;
    this.astarHist = dto.astarHist;
    this.bstarAvg = dto.bstarAvg ? Number(dto.bstarAvg.toFixed(2)) : undefined;
    this.bstarDeviation = dto.bstarDeviation
      ? Number(dto.bstarDeviation.toFixed(2))
      : undefined;
    this.bstarHist = dto.bstarHist;
    this.colorNameMode = dto.colorNameMode;
    this.colorNameHist = dto.colorNameHist;
    this.gourmetColorAvg = dto.gourmetColorAvg
      ? Number(dto.gourmetColorAvg.toFixed(2))
      : undefined;
    this.gourmetColorDeviation = dto.gourmetColorDeviation
      ? Number(dto.gourmetColorDeviation.toFixed(2))
      : undefined;
    this.coloretteColorAvg = dto.coloretteColorAvg
      ? Number(dto.coloretteColorAvg.toFixed(2))
      : undefined;
    this.coloretteColorDeviation = dto.coloretteColorDeviation
      ? Number(dto.coloretteColorDeviation.toFixed(2))
      : undefined;
    this.numberDefects = dto.numberDefects;
    this.numberDefectsCatOne = dto.numberDefectsCatOne;
    this.numberDefectsCatTwo = dto.numberDefectsCatTwo;
    this.defectsHist = dto.defectsHist;
    this.numberOfBeans = dto.numberOfBeans;
    this.comment = dto.comment;
  }
}

export interface LabSampleDto {
  id: string;
  createdDate: DateTimeString;
  pictureUrl?: string;
  lotNumber?: string;
  roastBatch?: RoastBatchPojo;
  coffeeType?: string;
  coffeeVariety?: string;
  screenSizeMode?: number;
  screenSizeHist?: CountBinValue[];
  lengthAvg?: number;
  lengthDeviation?: number;
  lengthHist?: CountBinValue[];
  widthAvg?: number;
  widthDeviation?: number;
  widthHist?: CountBinValue[];
  totalEstimatedVolume?: number;
  sampleMass?: number;
  density?: number;
  gourmetColorHist?: CountBinValue[];
  lstarAvg?: number;
  lstarDeviation?: number;
  lstarHist?: CountBinValue[];
  astarAvg?: number;
  astarDeviation?: number;
  astarHist?: CountBinValue[];
  bstarAvg?: number;
  bstarDeviation?: number;
  bstarHist?: CountBinValue[];
  colorNameMode?: string;
  colorNameHist?: CountBinValue[];
  gourmetColorAvg?: number;
  gourmetColorDeviation?: number;
  coloretteColorAvg?: number;
  coloretteColorDeviation?: number;
  numberDefects?: number;
  numberDefectsCatOne?: number;
  numberDefectsCatTwo?: number;
  defectsHist?: CountBinValue[];
  numberOfBeans?: number;
  comment: string;
}

export interface PaginatedLabSamples {
  items: LabSample[];
  pageInfo: PageInfo;
}

export interface CountBinValue {
  count: number;
  value: number;
}
