/* import __COLOCATED_TEMPLATE__ from './picture-data.hbs'; */
import Component from '@glimmer/component';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { DateTime } from 'luxon';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { service } from '@ember/service';
import PdfService from 'fabscale-app/services/pdf';
import { dropTask } from 'ember-concurrency';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { FormDataModel } from 'fabscale-app/models/form-data';
import LabSampleStore from 'fabscale-app/services/store/lab-sample';
import ErrorParserService from 'fabscale-app/services/error-parser';
import { UnitRoastColor } from 'fabscale-app/models/enums/units';

interface Args {
  labSample: LabSample;
  labSampleUpdate: (labSample: LabSample) => void;
  colorUnit: UnitRoastColor;
}

class FormData {
  @tracked lotNumber?: any;
  @tracked coffeeVariety?: any;
  @tracked comment?: any;
}

export default class RoastPicDetailsPictureData extends Component<Args> {
  @tracked formData: FormData;
  @tracked formModel: FormDataModel<FormData>;
  @tracked editMode: boolean;

  @service pdf: PdfService;
  @service errorParser: ErrorParserService;
  @service('store/lab-sample') labSamplesStore: LabSampleStore;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.formData = new FormData();

    const { labSample } = this.args;
    if (labSample) {
      this.formData.lotNumber = labSample.lotNumber;
      this.formData.coffeeVariety = labSample.coffeeVariety;
      this.formData.comment = labSample.comment;
    }

    this.formModel = new FormDataModel<FormData>({
      data: this.formData,
    });
  }

  get formattedCreatedDate() {
    const date = deserializeDate(this.args.labSample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  get roastBatchTimestamp() {
    if (this.args.labSample.roastBatch?.startDate) {
      return this.args.labSample.roastBatch?.startDate.toLocaleString(
        DateTime.DATETIME_SHORT
      );
    }
    return undefined;
  }

  get coffeeDefects() {
    if (
      this.args.labSample.numberDefectsCatOne !== undefined &&
      this.args.labSample.numberOfBeans
    ) {
      const defectsPercentage = Math.round(
        (100 * this.args.labSample.numberDefectsCatOne) /
          this.args.labSample.numberOfBeans
      );
      return `${defectsPercentage}% (${this.args.labSample.numberDefectsCatOne} of ${this.args.labSample.numberOfBeans})`;
    }

    return undefined;
  }

  get coffeeRoastScore() {
    if (this.args.colorUnit === 'PROBAT_COLORETTE') {
      if (
        this.args.labSample.coloretteColorAvg !== undefined &&
        this.args.labSample.coloretteColorDeviation !== undefined
      ) {
        return (
          this.args.labSample.coloretteColorAvg +
          ' ± ' +
          this.args.labSample.coloretteColorDeviation
        );
      }
    } else {
      if (
        this.args.labSample.gourmetColorAvg !== undefined &&
        this.args.labSample.gourmetColorDeviation !== undefined
      ) {
        return (
          this.args.labSample.gourmetColorAvg +
          ' ± ' +
          this.args.labSample.gourmetColorDeviation
        );
      }
    }

    return undefined;
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-roastpic-picture-data-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  @action
  async update() {
    this.editMode = false;

    const { sampleMass } = this.args.labSample;
    const { lotNumber, coffeeVariety, comment } = this.formData;

    const input = {
      lotNumber,
      coffeeVariety,
      sampleMass,
      comment,
    };

    try {
      await this.labSamplesStore
        .updateLabSample(this.args.labSample.id, input)
        .then((labSample) => {
          this.args.labSampleUpdate(labSample);
        });
    } catch (error) {
      this.formModel.addError(this.errorParser.getErrorMessage(error));
      return;
    }
  }

  @action
  startEditMode() {
    this.editMode = true;
  }

  @action
  updateLotNumber(lotNumber: string) {
    this.formModel.updateProperty('lotNumber', lotNumber);
  }

  @action
  updateCoffeeVariety(coffeeVariety: string) {
    this.formModel.updateProperty('coffeeVariety', coffeeVariety);
  }

  @action
  updateComment(comment: string) {
    this.formModel.updateProperty('comment', comment);
  }
}
