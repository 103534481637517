/* import __COLOCATED_TEMPLATE__ from './size.hbs'; */
import Component from '@glimmer/component';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { DateTime } from 'luxon';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { Chart } from 'chart.js/auto';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import PdfService from 'fabscale-app/services/pdf';

interface Args {
  labSample: LabSample;
  labSampleUpdate: (labSample: LabSample) => void;
}

export default class RoastPicDetailsSize extends Component<Args> {
  @service pdf: PdfService;

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-roastpic-size-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  @action
  drawScreenSizeChart(element: any) {
    const labels = Array.from(
      new Set(
        this.args.labSample.screenSizeHist?.map((countBin) => countBin.count)
      )
    );
    const ctx = element.getContext('2d');

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: this.args.labSample.screenSizeHist,
            backgroundColor: '#0F7799',
          },
        ],
      },
      options: {
        parsing: {
          xAxisKey: 'count',
          yAxisKey: 'value',
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  @action
  drawWidthChart(element: any) {
    const labels = Array.from(
      new Set(this.args.labSample.widthHist?.map((countBin) => countBin.count))
    );
    const ctx = element.getContext('2d');

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: this.args.labSample.widthHist,
            backgroundColor: '#0F7799',
          },
        ],
      },
      options: {
        parsing: {
          xAxisKey: 'count',
          yAxisKey: 'value',
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  @action
  drawLengthChart(element: any) {
    const labels = Array.from(
      new Set(this.args.labSample.lengthHist?.map((countBin) => countBin.count))
    );
    const ctx = element.getContext('2d');

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: this.args.labSample.lengthHist,
            backgroundColor: '#0F7799',
          },
        ],
      },
      options: {
        parsing: {
          xAxisKey: 'count',
          yAxisKey: 'value',
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  @action
  onLabSampleUpdate(labSample: LabSample) {
    this.args.labSampleUpdate(labSample);
  }

  get formattedCreatedDate() {
    const date = deserializeDate(this.args.labSample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  get length() {
    if (
      this.args.labSample.lengthAvg !== undefined &&
      this.args.labSample.lengthDeviation !== undefined
    ) {
      return `${this.args.labSample.lengthAvg} ± ${this.args.labSample.lengthDeviation} mm`;
    }

    return '-';
  }

  get width() {
    if (
      this.args.labSample.widthAvg !== undefined &&
      this.args.labSample.widthDeviation !== undefined
    ) {
      return `${this.args.labSample.widthAvg} ± ${this.args.labSample.widthDeviation} mm`;
    }

    return '-';
  }
}
