/* import __COLOCATED_TEMPLATE__ from './coffee-defect.hbs'; */
import Component from '@glimmer/component';

interface Args {
  record: any;
}

export default class CoffeeDefect extends Component<Args> {
  get coffeeDefects() {
    if (
      this.args.record.numberDefectsCatOne !== undefined &&
      this.args.record.numberOfBeans
    ) {
      const defectsPercentage = Math.round(
        (100 * this.args.record.numberDefectsCatOne) /
          this.args.record.numberOfBeans
      );
      return `${defectsPercentage}% (${this.args.record.numberDefectsCatOne} of ${this.args.record.numberOfBeans})`;
    }

    return '-';
  }
}
