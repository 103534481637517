import Component from '@glimmer/component';
import {
  RoastpicSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import { action } from '@ember/object';
import {
  TableColumnDefinition,
  TableColumnDefinitionInput,
} from 'fabscale-app/models/table-column-definition';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import CellId from 'fabscale-app/components/page/roast-pic/table/cell/id';
import { DateFormat } from 'fabscale-app/utilities/utils/format-date';
import CellCoffeeType from 'fabscale-app/components/page/roast-pic/table/cell/coffee-type';
import CellCoffeeColor from 'fabscale-app/components/page/roast-pic/table/cell/coffee-color';
import CellCoffeeDefect from 'fabscale-app/components/page/roast-pic/table/cell/coffee-defect';
import CellActions from 'fabscale-app/components/page/roast-pic/table/cell/actions';
import CellToggleCheckbox from 'fabscale-app/components/ui/table/cell/toggle-checkbox';
import { cached, tracked } from '@glimmer/tracking';
import { TableGetRowInfoHandler } from 'fabscale-app/helpers/data-table/get-row-info';
import { TableGetColumnInfoHandler } from 'fabscale-app/helpers/data-table/get-column-info';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { cloneDeep } from 'lodash-es';

interface Args {
  data: any;
  page?: number;
  pageSize?: number;
  sortBy?: RoastpicSortOption;
  sortDirection?: SortDirection;
  filters?: any;
  updatePage: (page?: number) => void;
  updatePageSize: (pageSize?: number) => void;
  updateSortBy: (sortBy?: RoastpicSortOption) => void;
  updateSortDirection: (sortDirection?: SortDirection) => void;
  toggleEditRow: (labSample: LabSample | undefined) => void;
  recordToggled: (selectedItems: any) => void;
}

type LabSampleOrEditRow =
  | LabSample
  | {
      edit: boolean;
      labSample: LabSample;
    };

export default class PageRoastPicTableDataTable extends Component<Args> {
  @service l10n: L10nService;

  @tracked editId?: string;

  @tracked updatedLabSamples: LabSample[] = [];
  @tracked selectedItems = new Set();

  get page() {
    return this.args.page || 1;
  }

  get pageSize() {
    return this.args.pageSize || 25;
  }

  @cached
  get columns(): TableColumnDefinitionInput[] {
    let { l10n } = this;

    let cols: TableColumnDefinitionInput[] = [
      {
        title: '',
        component: CellToggleCheckbox,
        tdClass: 'print-hide',
        thClass: 'print-hide',
      },
      {
        propertyName: 'id',
        title: l10n.t('ID'),
        component: CellId,
        thClass: 'nowrap',
        tdClass: 'nowrap',
        noCompactTitle: true,
        sortBy: 'ID',
      },
      {
        propertyName: 'roastBatch.startDate',
        title: l10n.t('Batch timestamp'),
        sortBy: 'CREATED_DATE',
        cellType: 'DATE',
        cellData: {
          dateFormat: DateFormat.DateTime,
        },
      },
      {
        propertyName: 'roastBatch.plantAsset.name',
        title: l10n.t('Roaster'),
        sortBy: 'ROAST_BATCH_NUMBER',
        cellType: 'DEFAULT',
      },
      {
        propertyName: 'lotNumber',
        title: l10n.t('Lot number'),
        thClass: 'nowrap ',
        tdClass: 'nowrap ',
        sortBy: 'LOT_NUMBER',
        cellType: 'DEFAULT',
      },
      {
        propertyName: 'coffeeType',
        title: l10n.t('Coffee type'),
        thClass: 'nowrap ',
        tdClass: 'nowrap ',
        sortBy: 'COFFEE_TYPE',
        component: CellCoffeeType,
      },
      {
        propertyName: 'coffeeVariety',
        title: l10n.t('Coffee variety'),
        thClass: 'nowrap ',
        tdClass: 'nowrap ',
        sortBy: 'COFFEE_VARIETY',
        cellType: 'DEFAULT',
      },
      {
        propertyName: 'screenSizeMode',
        title: l10n.t('Size'),
        thClass: 'nowrap ',
        tdClass: 'nowrap ',
        sortBy: 'COFFEE_SIZE',
        cellType: 'DEFAULT',
      },
      {
        propertyName: 'coffeeColor',
        title: l10n.t('Color'),
        thClass: 'nowrap',
        tdClass: 'nowrap',
        sortBy: 'COFFEE_COLOR',
        component: CellCoffeeColor,
      },
      {
        propertyName: 'numberDefectsCatOne',
        title: l10n.t('Defect'),
        sortBy: 'NUMBER_DEFECTS',
        thClass: 'nowrap',
        tdClass: 'nowrap',
        component: CellCoffeeDefect,
      },
    ];

    cols.push({
      propertyName: '',
      title: '',
      component: CellActions,
    });

    return cols;
  }

  @cached
  get rowData(): LabSampleOrEditRow[] {
    const labSamples = this.updateLabSamples(cloneDeep(this.args.data));

    return this.addEditRow(cloneDeep(labSamples));
  }

  private addEditRow(labSamples: LabSample[]): LabSampleOrEditRow[] {
    const rowData: LabSampleOrEditRow[] = labSamples;

    if (this.editId) {
      const labSample = labSamples.find(({ id }) => id === this.editId);

      if (labSample) {
        const i = rowData.indexOf(labSample);
        const editRow = {
          edit: true,
          labSample,
        };
        rowData.splice(i + 1, 0, editRow);
      }
    }

    return rowData;
  }

  private updateLabSamples(labSamples: LabSample[]) {
    const { updatedLabSamples } = this;

    updatedLabSamples.forEach((labSample) => {
      const i = labSamples.findIndex(({ id }) => id === labSample.id);
      if (i > -1) {
        (labSamples as any)[i] = labSample;
      }
    });

    return labSamples;
  }

  @cached
  get getRowInfoHandler(): TableGetRowInfoHandler | undefined {
    const { rowData, editId } = this;

    if (editId) {
      return (record: LabSampleOrEditRow) => {
        const i = rowData.indexOf(record);
        const classes = ['transition-common'];

        if (i % 2) {
          classes.push('table__tr--striped');
        }

        if (!('edit' in record) && record.id !== editId) {
          classes.push('opacity-30');
        } else {
          classes.push('table__tr--striped');
        }

        return { class: classes.join(' ') };
      };
    }

    return (record: LabSampleOrEditRow) => {
      const i = rowData.indexOf(record);
      const classes = ['transition-common'];

      if (i % 2) {
        classes.push('table__tr--striped');
      }

      return { class: classes.join(' ') };
    };
  }

  @cached
  get getColumnInfoHandler(): TableGetColumnInfoHandler | undefined {
    if (!this.editId) {
      return undefined;
    }

    const columnCount = this.columns.length;

    return (column: TableColumnDefinition, record: LabSampleOrEditRow) => {
      if (!('edit' in record)) {
        return { colspan: undefined, showColumn: true };
      }
      // Editing this record - only show action column, except for UiTableCellToggleCheckbox
      return column.propertyName === '' && column.cellType === 'DEFAULT'
        ? { colspan: columnCount, showColumn: true }
        : { showColumn: false };
    };
  }

  @cached
  get selectedRecords() {
    return this.args.data.filter((item: any) =>
      this.selectedItems.has(item.id)
    );
  }

  @cached
  get isLimitReached() {
    return this.selectedItems.size >= 2;
  }

  @action
  updateSort(sortBy: RoastpicSortOption, sortDirection: SortDirection) {
    if (sortBy !== this.args.sortBy) {
      this.args.updateSortBy(sortBy);
    }

    if (sortDirection !== this.args.sortDirection) {
      this.args.updateSortDirection(sortDirection);
    }
  }

  @action
  toggleRecord(record: any) {
    if (this.selectedItems.has(record.id)) {
      this.selectedItems.delete(record.id);
    } else if (!this.isLimitReached) {
      this.selectedItems.add(record.id);
    }
    this.selectedItems = new Set([...this.selectedItems]);

    this.args.recordToggled(this.selectedItems);
  }

  @action
  toggleEditRow(data?: any) {
    this.editId = data?.id;

    this.args.toggleEditRow(data);
  }

  @action
  onEditRowSuccess(ev: any) {
    this.updatedLabSamples.push(ev);
    this.toggleEditRow(undefined);
  }

  @action
  resetEdit() {
    this.toggleEditRow(undefined);
    this.updatedLabSamples = [];
  }
}
